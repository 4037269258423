"use client";

import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Fade,
  ScaleFade,
  Slide,
  SlideFade,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";
import WithSubnavigation from "./WithSubnavigation";
import { useState, useEffect, useCallback, useRef } from "react";
import { useTransition, animated, useSpring } from "react-spring";
import { useSpringCarousel } from "react-spring-carousel";
import { RemoveScrollBar } from "react-remove-scroll-bar";

import cars from "../cars.json";
import Card from "./Card";

export default function Header() {
  const [visibility, setVisibility] = useState(-1);
  const [index, setIndex] = useState(0);
  const [visibleCards, setVisibleCards] = useState([0, 1]); // Initial indices of the visible cards

  function updateVisibility(val: number) {
    setVisibility(val);
  }

  // Spring Carousel
  const { carouselFragment, slideToPrevItem, slideToNextItem } =
    useSpringCarousel({
      slideType: "fluid",
      freeScroll: true,
      enableFreeScrollDrag: true,
      withLoop: true,
      disableGestures: true,
      withThumbs: false,
      items: cars.cars.map((car, idx) => ({
        id: "" + idx,
        renderItem: (
          <div style={{ margin: "0em 0.65em" }}>
            <Card
              key={index}
              headline={car.headline}
              short_description={car.short_description}
              description={car.description}
              brand={car.brand}
              model={car.model}
              status={car.status}
              year={car.year}
              condition={car.condition}
              origin={car.origin}
              owners={car.owners}
              ps={car.ps}
              fuel={car.fuel}
              mileage={car.mileage}
              images={car.images}
              visibility={visibility}
              setVisibility={updateVisibility}
              index={idx}
              price={"price" in car ? car?.price : undefined}
            />
          </div>
        ),
      })),
    });

  return (
    <>
      <Button
        style={{
          position: "absolute",
          zIndex: 999,
          backgroundColor: "rgb(21, 26, 36)",
          opacity: "70%",
        }}
        onClick={slideToPrevItem}
      >
        <ChevronLeftIcon color={"white"}></ChevronLeftIcon>
      </Button>
      <Button
        style={{
          position: "absolute",
          zIndex: 999,
          right: "0px",
          backgroundColor: "rgb(21, 26, 36)",
          opacity: "70%",
        }}
        onClick={slideToNextItem}
      >
        <ChevronRightIcon color={"white"}></ChevronRightIcon>
      </Button>
      <Box overflow="hidden">{carouselFragment}</Box>
    </>
  );
}
