"use client";

import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import WithSubnavigation from "./WithSubnavigation";

export default function Impressum() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box height={"80vh"} width={"100vw"} margin={"2em"} alignSelf={"center"}>
      <Box alignContent={"center"} width={"40vw"}>
        <Heading size={"lg"}>Impressum</Heading>
        <br />
        <Text>
          <p>
            <b>Inhaber:</b> <br />
            Gregor Christian Diekmeier
            <br />
            Keffenbrinkweg 4<br />
            12249 Berlin
            <br />
            E-Mail: diekmeier77@onet.eu
            <br />
            Tel +49 17643540134
            <br />
          </p>
          <p>Gewerberegister Berlin</p>
          <p>Ust-IdNr: DE260032050</p>
          <br />
          <strong>Haftung für Inhalte</strong>
          <p>
            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für
            die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können
            wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir
            gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
            allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
            als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
            gespeicherte fremde Informationen zu überwachen oder nach Umständen
            zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </p>
          <br />
          <strong>Datenschutz</strong>
          <p>
            Die Nutzung unserer Webseite ist in der Regel ohne eine Angabe
            personenbezogener Daten möglich. Soweit auf unseren Seiten
            personenbezogene Daten (beispielsweise Name, Anschrift oder
            E-Mail-Adresse) erhoben werden, erfolgt dies – soweit es möglich
            ist– immer auf freiwilliger Basis. Wir geben Ihre Daten ohne Ihre
            ausdrückliche Zustimmung nicht an Dritte weiter. Außerdem weisen wir
            Sie darauf hin, dass die Datenübertragung im Internet (wie
            beispielsweise bei der Kommunikation über E-Mail) Sicherheitslücken
            aufweisen kann. Denn ein lückenloser Schutz der Daten vor dem
            Zugriff durch Dritte ist nicht möglich. Wir widersprechen hiermit
            ausdrücklich der Nutzung von im Rahmen der Impressumspflicht
            veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht
            ausdrücklich angeforderter Werbung und Informationsmaterialien. Die
            Betreiber dieser Seiten behalten sich ausdrücklich vor, im Fall der
            unverlangten Zusendung von Werbeinformationen, etwa durch
            Spam-Mails, rechtliche Schritte einzuleiten.
          </p>
        </Text>
      </Box>
    </Box>
  );
}
