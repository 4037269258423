"use client";

import {
  Avatar,
  Box,
  Button,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { BiAlignJustify } from "react-icons/bi";
import { FaLinkedin } from "react-icons/fa";

export default function Testimonial() {
  return (
    <Stack
      py={16}
      px={8}
      spacing={{ base: 8, md: 10 }}
      align={"center"}
      direction={"column"}
    >
      <Text
        fontSize={{ base: "xl", md: "2xl" }}
        textAlign={"center"}
        maxW={"3xl"}
      >
        "Die Restauration, Reparatur und der Verkauf von Luxus- und Sportwagen
        sind für mich mehr als nur ein Nebenberuf - es ist eine Art, Tradition
        und Moderne zu vereinen und gleichzeitig einen positiven Beitrag zur
        Umwelt zu leisten. Bei Mercedes-Benz habe ich gelernt, wie wichtig
        Qualität und Innovation sind. Diese Werte übertrage ich auf unser
        Familiengeschäft. Unser familiäres Erbe und die Passion für exzellente
        Automobile treiben mich jeden Tag an, um die Mission unseres
        Unternehmens weiterzuführen."
      </Text>
      <Box textAlign={"center"}>
        <Avatar
          src={
            "https://media.licdn.com/dms/image/D4E03AQHAPXtWtNevOg/profile-displayphoto-shrink_800_800/0/1702293532058?e=1716422400&v=beta&t=VwGTkwC2wRpbcxNfzgKVstw9AKrNItWRZqdW-T4iaZw"
          }
          mb={2}
        />
        <Stack direction={"row"} spacing={{ base: 1 }}>
          <Text fontWeight={600} marginLeft={"1em"}>
            Oliver Diekmeier, M.Sc.{" "}
          </Text>
          <a href="https://www.linkedin.com/in/oliver-diekmeier-567639179/">
            <FaLinkedin style={{ alignSelf: "center" }} />
          </a>
        </Stack>
        <Text fontSize={"sm"} color={useColorModeValue("gray.400", "gray.400")}>
          Projektleiter bei Mercedes-Benz AG
        </Text>
      </Box>
    </Stack>
  );
}
