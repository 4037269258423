import { extendTheme } from "@chakra-ui/react";
import "@fontsource-variable/cinzel";
import "@fontsource-variable/quicksand";

const theme = extendTheme({
  fonts: {
    heading: `'Cinzel Variable', sans-serif`,
    body: `'Quicksand Variable', sans-serif`,
  },
  overflow: "hidden",
});

export default theme;
