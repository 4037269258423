"use client";

import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import WithSubnavigation from "./WithSubnavigation";

export default function Header() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <WithSubnavigation />
    </Box>
  );
}
