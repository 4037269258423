"use client";

import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaBeer } from "react-icons/fa";
import { ReactElement } from "react";
import { GiTrade } from "react-icons/gi";
import { GiAutoRepair } from "react-icons/gi";
import { FaSearchLocation } from "react-icons/fa";

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Flex
        w={8}
        h={8}
        align={"center"}
        justify={"center"}
        rounded={"full"}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export default function Partnerships() {
  return (
    <Container maxW={"5xl"} py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Flex>
          <Image
            rounded={"md"}
            alt={"feature image"}
            src={"./images/partnerships.png"}
            objectFit={"cover"}
          />
        </Flex>
        <Stack spacing={4}>
          <Text
            textTransform={"uppercase"}
            color={"black.400"}
            fontWeight={600}
            fontSize={"sm"}
            bg={useColorModeValue("blue.50", "blue.900")}
            p={2}
            alignSelf={"flex-start"}
            rounded={"md"}
          >
            Zusammenarbeit
          </Text>
          <Heading color="white">Werde unser Partner!</Heading>
          <Text color={"white"} fontSize={"lg"}>
            Wir sind stets auf der Suche nach passenden Partner für unser
            Geschäft. Sie sind ein spezialisierter Automobilverkäufer im B2B
            Bereich? Oder ein Experte für komplexe Reparaturen und
            Restaurationen? Suchen für Ihren Kunden nach einem ganz speziellen
            Fahrzeug? Dann sind Sie hier richtig! Wir freuen uns über Ihre
            Kontaktaufnahme.
          </Text>
          <Stack
            spacing={4}
            color={"white"}
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.100", "gray.700")}
              />
            }
          >
            <Feature
              icon={<Icon as={GiTrade} color={"yellow.500"} w={5} h={5} />}
              iconBg={useColorModeValue("yellow.100", "yellow.900")}
              text={"B2B Verkäufer oder Versicherungen"}
            />
            <Feature
              icon={<Icon as={GiAutoRepair} color={"green.500"} w={5} h={5} />}
              iconBg={useColorModeValue("green.100", "green.900")}
              text={"Spezialisten für Reparaturen oder Restauration"}
            />
            <Feature
              icon={
                <Icon as={FaSearchLocation} color={"purple.500"} w={5} h={5} />
              }
              iconBg={useColorModeValue("purple.100", "purple.900")}
              text={"Fahrzeug-Scouts im Kundenauftrag"}
            />
          </Stack>
        </Stack>
      </SimpleGrid>
    </Container>
  );
}
