import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  Avatar,
  useColorModeValue,
  Image,
  Button,
  IconButton,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  Spinner,
  position,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";
import { useState, useEffect, useReducer } from "react";

// Define the structure of your props
interface Card {
  price?: string;
  headline: string;
  short_description: string;
  description: string;
  brand: string;
  model: string;
  status: string;
  year: string;
  condition: string;
  origin: string;
  owners: string;
  ps: string;
  fuel: string;
  mileage: string;
  images: string[];
  visibility: number;
  setVisibility: Function;
  index: number;
}

export default function Card(props: Card) {
  const [imgIdx, setImgIdx] = useState(0);
  const [imageLoading, setImageLoading] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    forceUpdate();
  }, [props.visibility]);

  return (
    <Center py={6} maxWidth={"95vw"}>
      <Box
        maxW={"445px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.900")}
        boxShadow={"2xl"}
        rounded={"md"}
        p={6}
        overflow={"hidden"}
      >
        <Box
          style={{
            position: "absolute",
            background: props.status === "verkauft" ? "#000000" : "#08fc00",
            borderRadius: "0.2em",
            padding: "0.1em 0.8em",
            zIndex: "999",
          }}
        >
          <Text color={props.status === "verkauft" ? "white" : "black"}>
            <b>{props.status === "verkauft" ? "Verkauft" : "Verfügbar"}</b>
          </Text>
        </Box>
        <Box
          w={"445px"}
          bg={"gray.100"}
          mt={-6}
          mx={-6}
          mb={6}
          pos={"relative"}
        >
          <IconButton
            aria-label="Next cars"
            icon={<ChevronLeftIcon />}
            onClick={() => {
              setImageLoading(true);
              setImgIdx(imgIdx > 0 ? imgIdx - 1 : props.images.length - 1);
            }}
            style={{
              position: "absolute",
              left: "0.2em",
              top: "50%",
              backgroundColor: "rgba(255,255,255,0.5)",
            }}
          />
          {imageLoading === true ? (
            <Spinner
              style={{
                position: "absolute",
                top: "50%",
                left: "45%",
                zIndex: 999,
              }}
              thickness="6px"
              speed="1s"
              emptyColor="gray.200"
              color="#5982ff"
              size="xl"
            />
          ) : null}
          <Image
            src={props.images[imgIdx]}
            alt={props.headline}
            align={"center"}
            onClick={onOpen}
            onLoad={() => setImageLoading(false)}
          />
          <IconButton
            aria-label="Next cars"
            icon={<ChevronRightIcon />}
            onClick={() => {
              setImageLoading(true);
              setImgIdx(imgIdx < props.images.length - 1 ? imgIdx + 1 : 0);
            }}
            style={{
              position: "absolute",
              right:
                window.innerWidth < 445 ? 467 - window.innerWidth : "0.2em",
              top: "50%",
              backgroundColor: "rgba(255,255,255,0.5)",
            }}
          />
        </Box>
        <Stack>
          <Text
            color={"green.500"}
            textTransform={"uppercase"}
            fontWeight={800}
            fontSize={"sm"}
            letterSpacing={1.1}
          >
            {props.brand}
          </Text>
          <Heading
            color={useColorModeValue("gray.700", "white")}
            fontSize={"2xl"}
            fontFamily={"body"}
          >
            {props.headline}
          </Heading>
          <Text color={"gray.500"}>
            <b>Preis:</b>{" "}
            {"price" in props && props.price !== undefined
              ? props.price + "€"
              : "n/b"}{" "}
            <br />
            <b>Baujahr:</b> {props.year} <br />
            <b>Leistung:</b> {props.ps} <br />
            <b>Zustand:</b> {props.condition} <br />
            <b>Laufleistung:</b> {props.mileage} <br />
          </Text>
          {props.visibility === props.index ? (
            <>
              <Text color={"gray.500"}>
                <b>Vorbesitzer:</b> {props.owners} <br />
                <b>Kraftstoff:</b> {props.fuel} <br />
              </Text>
              <Text color={"gray.500"} style={{ whiteSpace: "pre-wrap" }}>
                {props.description}
              </Text>
              <Stack mt={6} direction={"row"} spacing={4} align={"center"}>
                <Button onClick={() => props.setVisibility(-1)}>
                  Verstecken
                </Button>
              </Stack>
            </>
          ) : (
            <>
              <Text color={"gray.500"} style={{ whiteSpace: "pre-wrap" }}>
                {props.short_description}
              </Text>
              <Stack mt={6} direction={"row"} spacing={4} align={"center"}>
                <Button onClick={() => props.setVisibility(props.index)}>
                  Mehr Details
                </Button>
              </Stack>
            </>
          )}
        </Stack>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
        <ModalOverlay />
        <ModalContent maxH="80%" maxW="80%">
          <ModalCloseButton
            color={"white"}
            backgroundColor={"black"}
            opacity={"70%"}
          />
          <Image
            src={props.images[imgIdx]}
            alt={props.headline}
            align={"center"}
            style={{ alignContent: "auto" }}
          />
        </ModalContent>
      </Modal>
    </Center>
  );
}
