"use client";

import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Image,
  Heading,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";

export default function Mission() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack
      minH={"100vh"}
      direction={{ base: "column", md: "row" }}
      marginTop={"2em"}
    >
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={6} w={"full"} maxW={"lg"}>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}>
            <Text
              as={"span"}
              position={"relative"}
              _after={{
                content: "''",
                width: "full",
                height: useBreakpointValue({ base: "20%", md: "30%" }),
                position: "absolute",
                bottom: 1,
                left: 0,
                bg: "#75b4e0",
                zIndex: -1,
              }}
            >
              Mission
            </Text>
            <br />{" "}
            <Text color={"#003153"} as={"span"}>
              Nachhaltiger Luxus und Sport
            </Text>{" "}
          </Heading>
          <Text fontSize={{ base: "md", lg: "lg" }} color={"gray.500"}>
            Willkommen bei <b>Luxus & Sport - Diekmeier Automobile</b>, Ihrem
            exklusiven Partner für luxuriöse und sportliche Automobile. Als
            Familienunternehmen sind wir seit <b>über 20 Jahren</b> von der
            Leidenschaft für außergewöhnliche Fahrzeuge angetrieben. Unser Herz
            schlägt für die Wiederbelebung von Gebrauchtfahrzeugen, deren
            Restauration und Reparatur, wodurch wir die Brücke zwischen
            Tradition und Moderne schlagen.
          </Text>
          <Text fontSize={{ base: "md", lg: "lg" }} color={"gray.500"}>
            Unser Sortiment umfasst handverlesene, unfallfreie sowie reparierte
            Automobile, die jede Straße in eine Bühne verwandeln. Wir glauben
            fest daran, dass Qualität immer über Quantität steht. Deshalb wählen
            wir jedes Fahrzeug sorgfältig aus, um sicherzustellen, dass es
            unseren hohen Standards entspricht. Durch die Restauration und den
            Wiederverkauf von Luxus- und Sportfahrzeugen tragen wir aktiv zur
            Nachhaltigkeit bei.
          </Text>
          <Text fontSize={{ base: "md", lg: "lg" }} color={"gray.500"}>
            Wir sehen es als unsere <b>Mission</b>, nicht nur außergewöhnliche
            Automobile zu liefern, sondern auch einen Beitrag zum Umweltschutz
            zu leisten. Jedes Auto, das wir restaurieren und wiederverkaufen,
            ist ein Zeichen unseres Engagements für die Umwelt und die Bewahrung
            wertvoller Ressourcen. Bei uns gehen Tradition, Leidenschaft und
            modernste Technik Hand in Hand. Wir laden Sie herzlich ein, Teil
            unserer Automobil-Leidenschaft zu werden und mit uns die Straßen zu
            erobern. Erleben Sie den Unterschied, den Hingabe und Qualität
            machen können. Willkommen in der Welt von{" "}
            <b>Luxus und Sport - Diekmeier Automobile</b>, wo jedes Auto eine
            Geschichte erzählt und jedes Detail zählt.
          </Text>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image
          alt={"Login Image"}
          objectFit={"cover"}
          src={"./images/website1.jpg"}
        />
      </Flex>
    </Stack>
  );
}
