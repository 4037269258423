import * as React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Divider,
  Heading,
  AbsoluteCenter,
  Grid,
} from "@chakra-ui/react";
import { Logo } from "./Logo";
import Header from "./components/Header";
import theme from "./theme";
import Mission from "./components/Mission";
import Team from "./components/Team";
import Testimonial from "./components/Testimonial";
import Inventory from "./components/Inventory";
import Partnerships from "./components/Partnerships";
import Footer from "./components/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Element } from "react-scroll";
import Impressum from "./components/Impressum";

export const App = () => (
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Element id="mission-section" name="mission-section">
                <Mission />
              </Element>
              <Element id="team-section" name="team-section">
                <div style={{ background: "#151a24" }}>
                  <Team />
                </div>
              </Element>
              <Testimonial />
              <div>
                <Element id="car-section" name="car-section">
                  <Box position="relative" padding="10">
                    <Divider />
                    <AbsoluteCenter bg="white" px="7">
                      <Heading
                        fontSize={"1.5em"}
                        color={"grey"}
                        textAlign={"center"}
                      >
                        <b>Unsere Fahrzeuge</b>
                      </Heading>
                    </AbsoluteCenter>
                  </Box>
                  <Inventory />
                </Element>
              </div>
              <Divider />
              <Element id="collaboration-section" name="collaboration-section">
                <div style={{ background: "#151a24" }}>
                  <Partnerships />
                </div>
              </Element>
            </>
          }
        />
        <Route path="/impressum" element={<Impressum></Impressum>} />
      </Routes>
    </BrowserRouter>
    <Footer />
  </ChakraProvider>
);
