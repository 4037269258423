"use client";

import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaBeer } from "react-icons/fa";
import { ReactElement } from "react";
import { GiFamilyHouse } from "react-icons/gi";
import { GiPeaks } from "react-icons/gi";
import { BiBadgeCheck } from "react-icons/bi";

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Flex
        w={8}
        h={8}
        align={"center"}
        justify={"center"}
        rounded={"full"}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export default function Team() {
  return (
    <Container maxW={"5xl"} marginTop={"2em"} py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Text
            textTransform={"uppercase"}
            color={"blue.400"}
            fontWeight={600}
            fontSize={"sm"}
            bg={useColorModeValue("blue.50", "blue.900")}
            p={2}
            alignSelf={"flex-start"}
            rounded={"md"}
          >
            Unser Team
          </Text>
          <Heading color="white">Familienunternehmen aus Leidenschaft</Heading>
          <Text color={"gray.100"} fontSize={"lg"}>
            <b>Luxus und Sport - Diekmeier Automobile </b> wird ausschließlich
            von unsere Familie geführt. Alle Fahrzeuge werden von uns sorgfältig
            ausgewählt und geprüft. Wir übernehmen den Transport und arbeiten
            mit langjährigen, vertrauten Partnern die unseren
            Qualitätsansprüchen gerecht werden bei Reparatur und Restauration.
          </Text>
          <Stack
            spacing={4}
            color={"white"}
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.100", "gray.700")}
              />
            }
          >
            <Feature
              icon={
                <Icon as={GiFamilyHouse} color={"yellow.500"} w={5} h={5} />
              }
              iconBg={useColorModeValue("white.100", "white.900")}
              text={"Familienunternehmen"}
            />
            <Feature
              icon={<Icon as={GiPeaks} color={"blue.500"} w={5} h={5} />}
              iconBg={useColorModeValue("white.100", "white.900")}
              text={"Qualitätsorientiert"}
            />
            <Feature
              icon={<Icon as={BiBadgeCheck} color={"green.500"} w={5} h={5} />}
              iconBg={useColorModeValue("white.100", "white.900")}
              text={">20 Jahre Erfahrung"}
            />
          </Stack>
        </Stack>
        <Flex>
          <Image
            rounded={"md"}
            alt={"feature image"}
            src={"./images/family_company_img1.jpg"}
            objectFit={"cover"}
          />
        </Flex>
      </SimpleGrid>
    </Container>
  );
}
